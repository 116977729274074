import React from 'react';
import {Form, Input, Button} from 'antd';
import {MailOutlined} from '@ant-design/icons';

const RememberPassword = (props) => {
    const [form] = Form.useForm();

    const handleSubmit = (values) => {
        const {handleSubmit} = props;
        handleSubmit('rememberPassword', values);
    }

    return (
        <Form form={form} onFinish={handleSubmit}>
            <Form.Item name="email" rules={[{ required: true, message: 'Пожалуйста, введите ваш email!'}]}>
                <Input size="large" prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="E-mail" />
            </Form.Item>
            <Form.Item className="mb-0 text-center">
                <Button size="large" htmlType="submit" className="login-form-button">
                    Сбросить пароль
                </Button>
            </Form.Item>
        </Form>
    )
}
export default RememberPassword;
