import React from 'react'
import {connect} from 'react-redux'
import {isMobile} from 'react-device-detect'
import LoginScreen from 'components/screens/login'

import Navigation from 'components/navigation/index'
import NavigationPhone from 'components/navigation/index.phone'

const _ = require('lodash')

class App extends React.PureComponent {
    render() {
        const {user} = this.props;

        let template = <LoginScreen />;
        if (!_.isNull(user.token) && !_.isEmpty(user.token) && !_.isUndefined(user.token)) {
            if (isMobile) template = <NavigationPhone user={user} />;
            else template = <Navigation user={user} />;
        }

        return (
            <section className={isMobile?"detect-mobile":""}>
                {template}
            </section>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

export default connect(mapStateToProps)(App);
