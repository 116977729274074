import React from 'react'
import {connect} from "react-redux"
import {Link} from "react-router-dom"

import {Badge, Drawer, List, Button, Typography} from 'antd';
import {NavBar} from 'antd-mobile';
import {MenuOutlined, LeftOutlined} from '@ant-design/icons'

import {RouterApp, BaseComponent} from './extra'
import * as action from 'stores/actions';

const {Title} = Typography;

class Navigation extends BaseComponent {
    render() {
        const self = this,
            {title, backAction, openDrawer} = self.state,
            {menu} = self.props;
        let shortTitle = null;

        const params = {
            setTitle: self.setTitle,
            setBack: self.setBack
        }

        let bavBarProps = {
            mode: "light",
            rightContent: [
                <Badge count={self.getCartAmount()} overflowCount={9} key="0">
                    <MenuOutlined className="MenuOutlined-Icon" onClick={self.onOpenDrawerChange} />
                </Badge>,
            ]
        }
        if (backAction) {
            bavBarProps['icon'] = <LeftOutlined />;
            bavBarProps['onLeftClick'] = backAction;
        }

        if (title) {
            shortTitle = title.slice(0, 25);
            if (shortTitle!=title) shortTitle += "..."
        }

        return (
            <RouterApp user={self.props.user} params={params}>
                <div className="NavBar-box">
                    <NavBar {...bavBarProps}>
                        {shortTitle}
                    </NavBar>
                </div>
                <Drawer
                    title="Меню"
                    onClose={self.onOpenDrawerChange}
                    visible={openDrawer}
                    bodyStyle={{padding: 0}}
                    >
                    <List>
                        <List.Item className="nav-Item" key="0">
                            <Link to="/products">
                                <Button size="small" type="link" onClick={self.onOpenDrawerChange}>Создать новую заявку</Button>
                            </Link>
                        </List.Item>
                        <List.Item className="nav-Item" key="1">
                            <Link to="/personalarea">
                                <Button size="small" type="link" onClick={self.onOpenDrawerChange}>Личные данные</Button>
                            </Link>
                        </List.Item>
                        <List.Item className="nav-Item" key="2">
                            <Link to="/order">
                                <Badge count={self.getCartAmount()} overflowCount={9}>
                                    <Button size="small" type="link" onClick={self.onOpenDrawerChange}>Корзина</Button>
                                </Badge>
                            </Link>
                        </List.Item>
                        <List.Item className="nav-Item" key="3">
                            <Button size="small" type="link" onClick={self.logout}>
                                Выход
                            </Button>
                        </List.Item>
                    </List>
                    <List
                        header={<Title className="text-center" level={4}>Доп. информация</Title>}>
                        {menu.map((val, idx) => {
                            return (
                                <List.Item className="nav-Item" key={idx}>
                                    <a target="_blank" rel="noopener noreferrer" href={val.link}>
                                        <Button size="small" type="link">{val.name}</Button>
                                    </a>
                                </List.Item>
                            )
                        })}
                    </List>
                </Drawer>
            </RouterApp>
        )
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart,
        menu: state.menu,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fnLogout: form => dispatch(action.fnLogout(form)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
