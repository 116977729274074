import React from 'react'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import StarBorder from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'

const Item = ({self, value, length}) => (
    <ListItem>
        <ListItemIcon>
            <IconButton disabled={value.is_main?true:false} onClick={() => self.handleFavorite(value.id)}>
                {value.is_main?(
                    <StarIcon color="primary" />
                ):(
                    <StarBorder />
                )}
            </IconButton>
        </ListItemIcon>
        <ListItemText primary={value.phone} />
        <ListItemSecondaryAction>
            <IconButton onClick={() => self.dialogShow(true, value)}>
                <EditIcon />
            </IconButton>
            {length>1?(
                <IconButton onClick={() => self.handleDelete(value.id)}>
                    <DeleteIcon />
                </IconButton>
            ):null}
        </ListItemSecondaryAction>
    </ListItem>
)
export default Item;
