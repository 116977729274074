import React from 'react';
import {Form, Input, Button} from 'antd';
import {LockOutlined} from '@ant-design/icons';

const ResetPassword = (props) => {
    const [form] = Form.useForm();

    const handleSubmit = (values) => {
        const {handleSubmit} = props;
        handleSubmit('resetPassword', values);
    }

    const {reset_token} = props;
    return (
        <Form form={form} onFinish={handleSubmit} initialValues={{
            token: reset_token
        }}>
            <Form.Item name="token" className="none">
                <Input type="hidden" />
            </Form.Item>
            <Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста, введите ваш пароль!' }]}>
                <Input.Password size="large" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} autoComplete="off" placeholder="Пароль" />
            </Form.Item>
            <Form.Item name="password_confirmation" rules={[{required: true, message: 'Пожалуйста, введите ваш пароль повторно!'}]}>
                <Input.Password size="large" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} autoComplete="off" placeholder="Повторите пароль" />
            </Form.Item>
            <Form.Item className="mb-0 text-center">
                <Button size="large" htmlType="submit" className="login-form-button">
                    Сбросить пароль
                </Button>
            </Form.Item>
        </Form>
    )
}
export default ResetPassword;
