import React, {Component, useCallback, useLayoutEffect, useState} from 'react'
import {Row, Col, Button, Slider, Divider, Input} from 'antd'
import {MinusOutlined, PlusOutlined} from '@ant-design/icons'
import {isMobile} from 'react-device-detect'

const InputGroup = Input.Group

const ContentFC = (props) => {
    const {item, content, addAmountItem} = props,
    [inputValue, setInputValue] = useState(item.numberTransportUnits);

    const onChange = value => setInputValue(value),
    onChangeButton = useCallback((type, value) => {
        let count = item.numberTransportUnits;

        if (type) {
            value += count;
            if (item.maxAmount && value>=item.maxAmount) {
                value = item.maxAmount
            }
        } else {
            value -= count;
            if (value<0) value = 0;
        }
        setInputValue(value);
    }, [item]);

    useLayoutEffect(() => {
        setInputValue(item.numberTransportUnits);
    }, [item])

    return (
        <div className="pricing-box">
            <table>
                <thead>
                <tr>
                    <td>Кол-во , {item.unit}.</td>
                    <td>Цена за {item.unit}., руб.</td>
                </tr>
                </thead>
                <tbody>
                {content.map((val, idx) => (
                    <tr key={idx}>
                        <td>{val.string}</td>
                        <td>{val.price}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div>
                <Divider orientation="left">Добавить в корзину</Divider>
            </div>
            <div className="mt-15">
                {!isMobile?(
                    <Row className="pb-25">
                        <Col span={24}>
                            <Slider
                                tooltipVisible
                                min={item.numberTransportUnits}
                                max={item.maxAmount?item.maxAmount:100}
                                step={item.numberTransportUnits}
                                onChange={onChange}
                                value={typeof inputValue === 'number' ? inputValue : 0}
                            />
                        </Col>
                    </Row>
                ):null}
                <Row>
                    <Col span={isMobile?24:12} className={isMobile?"mb-15":""}>
                        <InputGroup>
                            <Row>
                                <Col span={5} className="text-center pr-5">
                                    <Button
                                        icon={<MinusOutlined />}
                                        onClick={() => onChangeButton(false, inputValue)}
                                    />
                                </Col>
                                <Col span={14} className="text-center">
                                    <Input
                                        className="text-center"
                                        size="default"
                                        disabled
                                        value={inputValue}
                                        />
                                </Col>
                                <Col span={5} className="text-center pl-5">
                                    <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => onChangeButton(true, inputValue)}
                                    />
                                </Col>
                            </Row>
                        </InputGroup>
                    </Col>
                    <Col span={isMobile?24:12}>
                        <Button block type="primary" onClick={() => addAmountItem(item, inputValue)}>Добавить</Button>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

class Content extends Component {
    constructor(props) {
        super(props);

        const {item} = props;
        this.state = {
            inputValue: item.numberTransportUnits,
        };
    }

    onChange = value => {
        this.setState({inputValue: value});
    }

    onChangeButton = (type) => {
        const {item} = this.props;

        let inputValue = this.state.inputValue;
        let count = item.numberTransportUnits;

        if (type) {
            inputValue += count;
            if (item.maxAmount && inputValue>=item.maxAmount) {
                inputValue = item.maxAmount
            }
        } else {
            inputValue -= count;
            if (inputValue<0) inputValue = 0;
        }
        this.setState({inputValue});
    };

    render() {
        const self = this;
        const {inputValue} = self.state;
        const {item, content, addAmountItem} = self.props;

        return (
            <div className="pricing-box">
                <table>
                    <thead>
                    <tr>
                        <td>Кол-во , {item.unit}.</td>
                        <td>Цена за {item.unit}., руб.</td>
                    </tr>
                    </thead>
                    <tbody>
                    {content.map((val, idx) => (
                        <tr key={idx}>
                            <td>{val.string}</td>
                            <td>{val.price}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div>
                    <Divider orientation="left">Добавить в корзину</Divider>
                </div>
                <div className="mt-15">
                    {!isMobile?(
                        <Row className="pb-25">
                            <Col span={24}>
                                <Slider
                                    tooltipVisible
                                    min={item.numberTransportUnits}
                                    max={item.maxAmount?item.maxAmount:100}
                                    step={item.numberTransportUnits}
                                    onChange={this.onChange}
                                    value={typeof inputValue === 'number' ? inputValue : 0}
                                />
                            </Col>
                        </Row>
                    ):null}
                    <Row>
                        <Col span={isMobile?24:12} className={isMobile?"mb-15":""}>
                            <InputGroup>
                                <Row>
                                    <Col span={5} className="text-center pr-5">
                                        <Button
                                            icon={<MinusOutlined />}
                                            onClick={() => self.onChangeButton(false)}
                                        />
                                    </Col>
                                    <Col span={14} className="text-center">
                                        <Input
                                            className="text-center"
                                            size="default"
                                            disabled
                                            value={inputValue}
                                            />
                                    </Col>
                                    <Col span={5} className="text-center pl-5">
                                        <Button
                                            icon={<PlusOutlined />}
                                            onClick={() => self.onChangeButton(true)}
                                        />
                                    </Col>
                                </Row>
                            </InputGroup>
                        </Col>
                        <Col span={isMobile?24:12}>
                            <Button block type="primary" onClick={() => addAmountItem(item, inputValue)}>Добавить</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}

// export default Content;
export default ContentFC;
