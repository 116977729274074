import React from 'react';

import {Link} from "react-router-dom"
import {Tooltip, Button, Modal, Badge, Dropdown, Menu} from 'antd';
import {LogoutOutlined, QuestionOutlined, ShoppingCartOutlined} from '@ant-design/icons'


class BaseComponent extends React.PureComponent {

    state = {
        title: null,
        backAction: null,
        currentRoute: null,
        openDrawer: false
    }

    setTitle = title => this.setState({title})

    logout = () => {
        const props = this.props;
        Modal.confirm({
            title: 'Вы действительно хотите выйти?',
            okText: 'Да',
            cancelText: 'Нет',
            icon: <QuestionOutlined />,
            onOk() {
                props.fnLogout();
            }
        });
    }

    setBack = history => {
        const {pathname} = history.location;
        let action = null;
        if (pathname !== "/") {
            action = () => history.push("/")
        }
        this.setState({backAction: action, currentRoute: pathname})
    }

    getCartAmount = () => {
        const {cart} = this.props;
        let cartAmount = 0;
        Object.keys(cart.items).forEach((key, idx) => {
            cartAmount += cart.items[key]
        })
        return cartAmount;
    }

    getDropdownItems = (menu) => {
        return (
            <Menu>
                {menu.map((val, idx) => {
                    return (
                        <Menu.Item key={idx}>
                            <a target="_blank" rel="noopener noreferrer" href={val.link}>
                                {val.name}
                            </a>
                        </Menu.Item>
                    )
                })}
            </Menu>
        )
    }

    pageHeaderExtra = (menu) => {
        const self = this,
            dropdownItems = self.getDropdownItems(menu)

        return [
            <Dropdown
                key="0"
                className="mutated-button"
                overlay={dropdownItems}
                placement="bottomLeft" arrow>
                    <Button>Доп. информация</Button>
            </Dropdown>,
            <Link to="/products" key="1" className="ml-20">
                <Button type="danger" className="mutated-button">
                    Создать новую заявку
                </Button>
            </Link>,
            <Link to="/personalarea" key="2" className="ml-20">
                <Button className="mutated-button">
                    Личные данные
                </Button>
            </Link>,
            <Link to="/order" key="3">
                <Badge count={self.getCartAmount()} overflowCount={9} className="ml-20">
                    <Button shape="circle" icon={<ShoppingCartOutlined />} />
                </Badge>
            </Link>,
            <Tooltip placement="leftTop" title="Выход" key="4" className="ml-20">
                <Button onClick={this.logout} shape="circle" icon={<LogoutOutlined />} />
            </Tooltip>
        ]
    }

    onOpenDrawerChange = () => {
        this.setState({openDrawer: !this.state.openDrawer });
    }

    render() {return null}
}

export default BaseComponent;
