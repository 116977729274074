import React, {Component} from 'react';
import {connect} from "react-redux";
import {isMobile} from 'react-device-detect';
import {Typography} from 'antd';
import renderHTML from 'react-render-html';

import * as action from 'stores/actions';
const {Title, Paragraph} = Typography;

class PaymentAnswer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            title: null,
            message: null
        }
    }

    componentDidMount = () => {
        const self = this,
            {fnAuthPost, params} = self.props;

        self.props.setBack(self.props.history);
        if (fnAuthPost) {
            fnAuthPost('/api/order/checkPaymentStatus', params, function (response) {
                if (response.title) {
                    self.props.setTitle(response.title);
                }
                self.setState({
                    title: response.title?response.title:null,
                    message: response.message?response.message:null
                })
            })
        } else return self.props.history.push("/");
    }

    render() {
        const {title, message} = this.state;
        if (!message && !title) return null;
        return (
            <div className={isMobile?"phone-box":"mt-25"}>
                <div className={isMobile?"":"pb-25"}>
                    <Typography>
                        <Title>{title}</Title>
                        <Paragraph>
                            {renderHTML(message)}
                        </Paragraph>
                    </Typography>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fnAuthPost: (url, data, callback) => dispatch(action.fnAuthPost(url, data, callback)),
    };
}

export default connect(null, mapDispatchToProps)(PaymentAnswer);
