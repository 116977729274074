import React from 'react'
import {connect} from "react-redux";
import {Table, Button, Input, Row, Col, Card, Divider, Tooltip, Modal, Typography} from 'antd'
import {MinusOutlined, PlusOutlined, StarOutlined} from '@ant-design/icons'
import {isMobile} from 'react-device-detect'
import renderHTML from 'react-render-html'

const _ = require('lodash')
const InputGroup = Input.Group

class Containers extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            row: []
        }

        this.buildColumns = this.buildColumns.bind(this)
        this.buildRows = this.buildRows.bind(this)
        this.handleInput = this.handleInput.bind(this)
        this.changeAmountRow = this.changeAmountRow.bind(this)
    }

    componentDidMount() {
        this.buildRows();
    }

    componentDidUpdate(prevProps) {
        if (this.props.dataSource !== prevProps.dataSource) {
            this.buildRows();
        } else if (this.props.cart.loading !== prevProps.cart.loading) {
            this.buildRows();
        } else if (this.props.addedItems !== prevProps.addedItems) {
            this.changeAmountRow();
        }
    }

    buildRows = () => {
        const self = this,
            {cart} = self.props;

        let row = [];
        self.props.dataSource.forEach((value, idx) => {
            let val = Object.assign({}, value)
            val['amount'] = cart.containers[val.id]?cart.containers[val.id]:0;
            val['price'] = val.price;
            val['sum'] = 0;
            row.push(val)
        });

        self.setState({row}, function () {
            self.changeAmountRow();
        })
        self.props.handleContainersItems(row)
    }

    changeAmountRow = (isReturn = false) => {
        const self = this,
            {addedItems, nomenclature} = self.props;

        let row = Object.assign([], self.state.row);
        if (Object.keys(addedItems).length>0) {
            row.forEach((item, idx) => {
                let prodKey = _.findIndex(nomenclature, function(o) { return o.code == item.codeProduct})
                if (prodKey !== -1) {
                    let product = nomenclature[prodKey];
                    if (addedItems[product.id]) {
                        row[idx].sum = (addedItems[product.id] - item.amount) * item.price;
                    } else {
                        row[idx].amount = 0;
                        row[idx].sum = 0;
                    }
                }
            });
        } else {
            row.map((val) => {
                val.sum = 0
                return val;
            })
        }

        self.setState({row})
        self.props.handleContainersItems(row)
    }

    handleInput(item, type) {
        const {addedItems, nomenclature} = this.props;
        let row = Object.assign([], this.state.row),
            key = _.findIndex(row, function(o) { return o.id == item.id})

        if (key!==-1) {
            if (type) {
                row[key].amount += 1;
            } else {
                row[key].amount -= 1;
                if (row[key].amount<0) row[key].amount = 0;
            }

            let productAmount = 0;
            let productKey = _.findIndex(nomenclature, function(o) { return o.code == row[key].codeProduct})
            if (productKey !== -1 && addedItems[nomenclature[productKey].id]) {
                productAmount = addedItems[nomenclature[productKey].id]
            }

            row[key].sum = (productAmount - row[key].amount) * row[key].price;
            // НОВАЯ ДОРАБОТКА !!!
            if (row[key].sum < 0) row[key].sum = 0;

            this.setState({row})
            this.props.handleContainersItems(row)
        }
    }

    buildColumns() {
        const handleInput = this.handleInput;
        return [
            {
                title: 'Наименование',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Количество',
                dataIndex: 'amount',
                key: 'amount',
                width: 190,
                render: (text, record) => {
                    return (
                        <InputGroup>
                            <Row>
                                <Col span={5} className="text-center pr-5">
                                    <Button
                                        icon={<MinusOutlined />}
                                        onClick={() => handleInput(record, false)}
                                    />
                                </Col>
                                <Col span={14} className="text-center">
                                    <Input
                                        className="text-center"
                                        size="default"
                                        disabled
                                        value={text}
                                        />
                                </Col>
                                <Col span={5} className="text-center pl-5">
                                    <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => handleInput(record, true)}
                                    />
                                </Col>
                            </Row>
                        </InputGroup>
                    )
                }
            },
            {
                title: 'Цена за ед.',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: 'Оплата за тару',
                dataIndex: 'sum',
                key: 'sum',
            },
        ];
    }

    modalTara = () => {
        const {setting} = this.props;
        Modal.info({
            title: 'Условия возврата тары',
            content: renderHTML(setting.rules),
            onOk() {},
        });
    }

    render() {
        const self = this,
            {setting} = this.props,
            {row} = this.state,
            handleInput = this.handleInput;

        if (!row || row.length === 0) return null;
        if (isMobile) {
            return (
                <Row className="phone-box pt-0 pb-0 mt-25">
                    <Divider>
                        Возвращаемая тара
                        <Tooltip title="Условия возврата тары">
                            {/* <Button onClick={self.modalTara} className="ml-15" type="primary" shape="circle" icon={<StarOutlined />} /> */}
                        </Tooltip>
                    </Divider>
                    <div className='mb-15'>
                        {renderHTML(setting.rules)}
                    </div>
                    {row.map(function (val, inx) {
                        return (
                            <Col key={val.id} span={24}>
                                <Card
                                    size="small"
                                    className="mb-15"
                                    title={val.name}
                                    >
                                    <InputGroup>
                                        <Row>
                                            <Col span={5} className="text-center pr-5">
                                                <Button
                                                    icon={<MinusOutlined />}
                                                    onClick={() => handleInput(val, false)}
                                                />
                                            </Col>
                                            <Col span={14} className="text-center">
                                                <Input
                                                    className="text-center"
                                                    size="default"
                                                    disabled
                                                    value={val.amount}
                                                    />
                                            </Col>
                                            <Col span={5} className="text-center pl-5">
                                                <Button
                                                    icon={<PlusOutlined />}
                                                    onClick={() => handleInput(val, true)}
                                                />
                                            </Col>
                                        </Row>
                                    </InputGroup>
                                </Card>
                            </Col>
                        )
                    })}
                    {/* <Typography.Title level={4} className="pb-10">
                        Оплата за тару: {self.props.totalSumContainers} руб.
                    </Typography.Title> */}
                </Row>
            )
        }
        const columns = this.buildColumns();
        return (
            <Row className="mt-25">
                <Divider>
                    Возвращаемая тара
                    <Tooltip title="Условия возврата тары">
                        {/* <Button onClick={self.modalTara} className="ml-15" type="primary" shape="circle" icon={<StarOutlined />} /> */}
                    </Tooltip>
                </Divider>
                <Col span={24}>
                    <div className='mb-15'>
                        {renderHTML(setting.rules)}
                    </div>
                    <Table
                        // footer={() => (
                        //     <strong className="footer-table">{'Итого: ' + self.props.totalSumContainers + "  руб."}</strong>
                        // )}
                        size="small"
                        bordered={true}
                        pagination={false}
                        columns={columns}
                        dataSource={row}
                        rowKey={record => record.id}/>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = state => {
    return {setting: state.setting, cart: state.cart};
};

export default connect(mapStateToProps)(Containers);
