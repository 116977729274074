import React from 'react'
import {isMobile} from 'react-device-detect'
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import {Layout, Row, Col} from 'antd';

import {ReactComponent as AuthSvgTop} from 'styles/images/authSvgTop.svg';
import {ReactComponent as AuthSvgBottom} from 'styles/images/authSvgBottom.svg';
import {InitData, Orders, Order, Products, OrderShow, PersonalArea, PaymentAnswer} from 'components/screens'

import Child from './child'

const RouterApp = (props) => {
    const {user, params} = props;

    const isAllDataUser = user.phones && user.address;
    return (
        <div className="App">
            <Router>
                <Layout className="mh-100vh auth-forms-layout">
                    <div className="svg-top">
                        <AuthSvgTop />
                    </div>
                    <div className="svg-bottom">
                        <AuthSvgBottom />
                    </div>
                    <div className={isMobile?"phone-container-dashboard":"container dashboard"}>
                        {
                            (isAllDataUser)?(
                                <section>
                                    <Row>
                                        <Col span={24}>
                                            {props.children}
                                        </Col>
                                    </Row>
                                    <Switch>
                                        <Route path="/payment/:type/:orderId" children={<Child component={PaymentAnswer} functions={params} />} />
                                        <Route path="/order/show/:id" children={<Child component={OrderShow} functions={params} />} />
                                        <Route path="/products" children={<Child component={Products} functions={params} />} />
                                        <Route path="/order" children={<Child component={Order} functions={params} />} />
                                        <Route path="/personalarea" children={<Child component={PersonalArea} functions={params} />} />
                                        <Route path="/" children={<Child component={Orders} functions={params} />} />
                                    </Switch>
                                </section>
                            ):(
                                <InitData />
                            )
                        }
                    </div>
                </Layout>
            </Router>
        </div>
    )
}

export default RouterApp;
