import React from 'react';
import {Form, Row, Col, DatePicker, Button, Collapse} from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

const {Panel} = Collapse;

const Filter = ({handleSubmit}) => {
    const [form] = Form.useForm();

    return (
        <Collapse className="collapse-phone-box" expandIconPosition="right">
            <Panel header="Фильтр" key="1">
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    <Row>
                        <Col span={24}>
                            <Form.Item name="dateStart" label="Дата заказа с" className="wp-100">
                                <DatePicker inputReadOnly={true} size="large" className="wp-100" format="DD.MM.YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="dateEnd" label="Дата заказа по" className="wp-100">
                                <DatePicker inputReadOnly={true} size="large" className="wp-100" format="DD.MM.YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item className="wp-100 text-center mb-0" colon={false}>
                                <Button block size="large" type="primary" htmlType="submit">
                                    Применить
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Panel>
        </Collapse>
    )
}
export default Filter;
