import React, {Component} from 'react';
import {Layout, Row, Col} from 'antd';

import Forms from './extra';
import {ReactComponent as AuthSvgTop} from 'styles/images/authSvgTop.svg';
import {ReactComponent as AuthSvgBottom} from 'styles/images/authSvgBottom.svg';

class Login extends Component {
    render() {
        return (
            <Layout className="mh-100vh auth-forms-layout">
                <div className="svg-top">
                    <AuthSvgTop />
                </div>
                <div className="svg-bottom">
                    <AuthSvgBottom />
                </div>
                <Row type="flex" justify="space-around" align="middle" className="mh-100vh">
                    <Col xs={{ span: 21 }} sm={{ span: 13 }} md={{ span: 7 }} >
                        <Forms />
                    </Col>
                </Row>
            </Layout>
        );
    }
}

export default Login;
