import React, {Component} from 'react';
import {connect} from "react-redux";
import {Button} from 'antd';

import * as action from 'stores/actions';

import Login from './login';
import Registration from './registration';
import RememberPassword from './rememberPassword';
import ResetPassword from './resetPassword';

class Forms extends Component {
    constructor(props) {
        super(props);

        const {reset_token} = this.props;
        this.state = {
            typeForm: reset_token?'resetPassword':'login'
        }

        this.changeForm = this.changeForm.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    changeForm(typeForm) {
        this.setState({
            typeForm: typeForm
        })
    }

    handleSubmit(typeForm, values) {
        this.props.fnAuth({typeForm, values});
    }

    render() {
        const {typeForm} = this.state;
        const {reset_token} = this.props;

        let form = null;
        switch (typeForm) {
            case 'login':
                form = <Login handleSubmit={this.handleSubmit} />;
                break;
            case 'registration':
                form = <Registration handleSubmit={this.handleSubmit} />;
                break;
            case 'rememberPassword':
                form = <RememberPassword handleSubmit={this.handleSubmit} />;
                break;
            case 'resetPassword':
                form = <ResetPassword handleSubmit={this.handleSubmit} reset_token={reset_token} />;
                break;
            default:
                break;
        }

        return (
            <div className="auth-forms-container">
                <header>
                    <div className="logo">
                        <img src="/logo.png" alt="Logo" />
                    </div>
                </header>
                <div className="auth-forms">
                    {form}
                </div>
                {
                    typeForm!=='resetPassword'?(
                        <footer className={typeForm==="registration"?"mt-25":""}>
                            <p>
                                <Button onClick={() => this.changeForm(typeForm==='login'?'registration':'login')} type="link">{(typeForm==='login')?"Зарегистрироваться":"Авторизоваться"}</Button>
                            </p>
                            <p>
                                <Button onClick={() => this.changeForm('rememberPassword')} type="link">Вспомнить пароль</Button>
                            </p>
                        </footer>
                    ):null
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return { reset_token: state.reset_token };
};

function mapDispatchToProps(dispatch) {
    return {
        fnAuth: form => dispatch(action.fnAuth(form))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Forms);
