import React from 'react'
import {useParams, useHistory} from "react-router-dom"

const Child = ({component, functions}) => {
    let params = useParams();
    let history = useHistory()

    const props = {
        component,
        params,
        history,
        ...functions
    }
    const Component = component;
    return <Component {...props} />
}

export default Child;
