import React from 'react'
import {connect} from "react-redux";
import {Spin} from 'antd';
import FormOrder from './formOrder';
import * as action from 'stores/actions';
import CommonOrderClass from 'components/CommonOrderClass'

class Order extends CommonOrderClass {

    componentDidMount = () => {
        this.props.setTitle("Оформление заявки")
        this.props.setBack(this.props.history);
        this.props.fnGetDataSourceForNewOrder();
        this.handleChangeAmountItem({items: this.state.items});
    }

    render() {
        const self = this;
        const {items, totalSumItem, containers, totalSumContainers} = this.state;
        const {newOrder, cart, addressId} = this.props;

        if (newOrder.loading) {
            return (
                <div className="mt-25 text-center">
                    <Spin size="large" />
                </div>
            )
        }
        return (
            <div className="mt-25">
                <FormOrder
                    addressId={addressId}
                    cart={cart}
                    dataSource={newOrder}
                    containers={containers}
                    addedItems={items}
                    totalSumItem={totalSumItem}
                    totalSumContainers={totalSumContainers}
                    getItemPrice={self.getItemPrice}
                    handleSubmit={self.handleSubmit}
                    onChangeTab={self.onChange}
                    deleteItem={self.deleteItem}
                    tebleAddItem={self.tebleAddItem}
                    dataSourceContainers={newOrder.containers}
                    handleContainersItems={self.handleContainersItems}
                    handleChangeAmountItem={self.handleChangeAmountItem}/>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        newOrder: state.newOrder,
        cart: state.cart,
        addressId: state.addressId,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        fnSaveOrder: (form, callback) => dispatch(action.fnSaveOrder(form, callback)),
        fnAuthPost: (url, data, callback) => dispatch(action.fnAuthPost(url, data, callback)),
        fnUpdateCart: data => dispatch(action.fnUpdateCart(data)),
        fnGetDataSourceForNewOrder: form => dispatch(action.fnGetDataSourceForNewOrder(form)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);
