import React from 'react';
import { message, notification } from 'antd';

const axios = require('axios');
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';

var ls = require('local-storage');
var _ = require('lodash');

function print_message(data, status) {
    let content = "Error code " + status;

    if (!_.isUndefined(data.errors)) {
        content = data.errors;
        if (_.isObject(data.errors)) {
            content = Object.keys(data.errors).map(function (key, index) {
                return <p key={index} className="mb-0">{data.errors[key]}</p>;
            });
        }
    }

    notification.warning({
        message: 'Oops...',
        description: content,
    });
}

export const post = next => (route, body, colback) => axios.post(route, JSON.stringify(body))
    .then(function (response) {
        if(colback && response.data){
            colback(response.data, true);
        }
    })
    .catch(function (error) {
        // обработать ошибку
        if (error && error.response && (error.response.status===419 || error.response.status===401)) {
            window.location.reload();
        } else if (error && error.response && error.response.status!==422) {
            message.error("Ошибка сервера №" + error.response.status);
        } else if (error && error.response && error.response.data) {
            print_message(error.response.data, error.response.status)
        }
        colback(null, false);
    })
    .then(function () {
        // Всегда выполняется
    });

export const post_api = next => (api, route, data, colback) => axios.post(route, data, {
        headers: { 'Authorization': 'Bearer ' + api }
    }).then(function (response) {
        if (colback) {
            colback(response.data, true);
        }
    })
    .catch(function (error) {
        // обработать ошибку
        if (error && error.response && (error.response.status===419 || error.response.status===401)) {
            ls.remove('vista-token');
            window.location.reload();
        } else if (error && error.response && error.response.status!==422) {
            message.error("Ошибка сервера №" + error.response.status);
        } else if (error && error.response && error.response.data) {
            print_message(error.response.data, error.response.status)
        }
        colback(null, false);
    });
