import React from 'react'
import {Form, Input, Button} from 'antd'
import CheckboxBox from './checkbox'

const Registration = (props) => {
    const [form] = Form.useForm();

    const handleSubmit = (values) => {
        const {handleSubmit} = props;
        handleSubmit('registration', values);
    }

    const rows = [
        {
            name: "surname",
            placeholder: "Фамилия *",
            rules: [{ required: true, message: 'Поле обязательно для заполнения!' }]
        },
        {
            name: "name",
            placeholder: "Имя *",
            rules: [{ required: true, message: 'Поле обязательно для заполнения!' }]
        },
        {
            name: "middle_name",
            placeholder: "Отчество",
            rules: [{ required: false, message: 'Поле обязательно для заполнения!' }]
        },
        {
            name: "email",
            placeholder: "E-mail *",
            rules: [
                { type: 'email', message: 'Введен неверный E-mail' },
                { required: true, message: 'Поле обязательно для заполнения!' }
            ],
        },
        {
            name: "password",
            placeholder: "Пароль *",
            rules: [{ required: true, message: 'Поле обязательно для заполнения!' }],
            isPassword: true
        },
        {
            name: "password_confirmation",
            placeholder: "Повторите пароль *",
            rules: [{ required: true, message: 'Поле обязательно для заполнения!' }],
            isPassword: true
        },
    ]

    return (
         <Form form={form} onFinish={handleSubmit}>
            {rows.map(function (val, idx) {
                return (
                    <Form.Item key={idx} name={val.name} rules={val.rules}>
                        {val.isPassword?(
                            <Input.Password size="large" autoComplete="new-password" placeholder={val.placeholder} />
                        ):(
                            <Input size="large" placeholder={val.placeholder} />
                        )}
                    </Form.Item>
                )
            })}
            <CheckboxBox />
            <Form.Item shouldUpdate className="mb-0 text-center">
               {() => {
                   const values = form.getFieldsValue()
                   const disabled = values.i_agree?false:true
                   return (
                       <Button disabled={disabled} size="large" htmlType="submit" className="login-form-button">
                           Зарегистрироваться
                       </Button>
                   )
               }}
           </Form.Item>
         </Form>
    )
}
export default Registration;
