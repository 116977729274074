import { handleActions } from 'redux-actions';

const initialState = {
    addressId: 0,
    user: {
        token: "",
        phones: 0,
        address: 0
    },
    reset_token: null,
    loading: false,
    setting: {},
    menu: [],
    orders: {
        dataSource: [],
        loading: true,
    },
    newOrder: {
        paymentMethod: [],
        timeOfDelivery: [],
        deliveryAddress: [],
        nomenclature: [],
        nomenclatureGroup: [],
        containers: [],
        deliveryRules: {},
        loading: true,
    },
    cart: {
        addressStr: "",
        totalSumItem: 0,
        totalSumContainers: 0,
        items: {},
        containers: {},
        loading: true,
    }
}

export default handleActions(
    {
        "SET_RESET_TOKEN": (state, action) => {
            const { payload } = action
            return {
                ...state,
                reset_token: payload
            }
        },
        "LOGIN_RECEIVED": (state, action) => {
            const { payload } = action
            return {
                ...state,
                user: payload
            }
        },
        "SET_ORDERS": (state, action) => {
            const { payload } = action
            return {
                ...state,
                orders: payload
            }
        },
        "SET_NEW_ORDER": (state, action) => {
            const { payload } = action
            return {
                ...state,
                newOrder: payload
            }
        },
        "SET_SETTING_AND_MENU": (state, action) => {
            const { payload } = action
            return {
                ...state,
                setting: payload.setting,
                menu: payload.menu,
            }
        },
        "SET_CART": (state, action) => {
            const { payload } = action
            return {
                ...state,
                cart: payload
            }
        },
        "SET_ADDRESS_ID": (state, action) => {
            const { payload } = action
            return {
                ...state,
                addressId: payload
            }
        },
    },
    initialState
)
