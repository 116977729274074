import React from 'react'
import {Form, Radio, Row, Col} from 'antd';
const _ = require('lodash')

const changeAmountRow = (form, addedItems, containers, dataSource, dataSourceContainers) => {
    const {nomenclature} = dataSource;
    let results = false,
        counter = {
            items: 0,
            containers: 0
        }

    if (Object.keys(addedItems).length>0) {
        Object.keys(addedItems).forEach((id, idx) => {
            let prodKey = _.findIndex(nomenclature, function(o) { return o.id == id})
            if (prodKey !== -1) {
                let product = nomenclature[prodKey];
                let key = _.findIndex(dataSourceContainers, function(o) { return o.codeProduct == product.code})
                if (key !== -1) {
                    counter.items += addedItems[id];
                    counter.containers += containers[dataSourceContainers[key].id];
                }
            }
        })

        if (counter.items<counter.containers) {
            results = true;
        }
    } else {
        results = true;
    }

    let values = form.getFieldValue();
    if (values.paymentMethod) {
        form.setFieldsValue({paymentMethod: null})
    }

    return results;
}

const PaymentMethod = ({
    form,
    dataSourceContainers,
    containers,
    addedItems,
    dataSource,
    getFieldDecorator
}) => {
    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
    };

    // const closeOnlinePayment = changeAmountRow(form, addedItems, containers, dataSource, dataSourceContainers);
    const closeOnlinePayment = false;

    return (
        <Row>
            <Col span={16} className="pr-10 pl-10">
                <Form.Item
                    name="paymentMethod"
                    label="Способ оплаты"
                    className="wp-100"
                    rules={[{required: true, message: 'Поле обязательно для заполнения'}]}>
                    <Radio.Group>
                        {dataSource.paymentMethod.map(function (value, index) {
                            let props = {
                                style: radioStyle,
                                value: index,
                                key: index,
                                disabled: false
                            }
                            if (index==1 && closeOnlinePayment) {
                                props.disabled = true;
                            }
                            return <Radio {...props}>{value}</Radio>
                        })}
                    </Radio.Group>
                </Form.Item>
            </Col>
        </Row>
    )
}
export default PaymentMethod;
