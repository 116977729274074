import React from 'react'
import {connect} from "react-redux";
import {isMobile} from 'react-device-detect'
import {Descriptions, Badge, Table, Typography} from 'antd'
import * as action from 'stores/actions';
const {Title} = Typography;

const TableItems = ({name, items}) => {
    const columns = [
        {
            title: 'Наименование',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Количество',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Сумма',
            dataIndex: 'sum',
            key: 'sum',
        }
    ]

    let totalSum = 0;
    if (items) {
        items.forEach((item) => {
            totalSum += item.cost;
        })
    }

    return (
        <div className={isMobile?"pt-10":"pb-25"}>
            <Title level={4}>{name}</Title>
            {
                isMobile?(
                    <div>
                        {items.map(function (item, idx) {
                            return (
                                <Descriptions
                                    key={idx}
                                    className="mb-15"
                                    bordered
                                    size="small"
                                    >
                                    {
                                        columns.map(function (val, i) {
                                            return (
                                                <Descriptions.Item label={val.title} key={val.key}>
                                                    {item[val.key]}
                                                </Descriptions.Item>
                                            )
                                        })
                                    }
                                </Descriptions>
                            )
                        })}
                        <Typography.Title level={4} className="text-rigrt pb-10">
                            Итого: {totalSum} руб.
                        </Typography.Title>
                    </div>
                ):(
                    <Table
                        bordered={true}
                        columns={columns}
                        dataSource={items}
                        pagination={false}
                        footer={() => (
                            <strong className="footer-table">{'Итого: ' + totalSum + "  руб."}</strong>
                        )}
                    />
                )
            }
        </div>
    )
}

class OrderShow extends React.PureComponent {

    state = {
        dataSource: {
            order: {},
            items: [],
            containers: [],
        }
    }

    componentDidMount = () => {
        const self = this;
        self.props.setBack(this.props.history);
        const {fnAuthPost, params} = self.props;
        if (fnAuthPost) {
            fnAuthPost('/api/order/getdescription', {id: params.id}, function (response) {
                if (response.title) {
                    self.props.setTitle(response.title);
                    self.setState({dataSource: response})
                }
            })
        }
    }

    render() {
        const {dataSource} = this.state;
        const {order, items, containers} = dataSource;

        return (
            <div className={isMobile?"phone-box":"mt-25"}>
                <div className={isMobile?"":"pb-25"}>
                    <Title className={isMobile?"pb-10":""} level={4}>Основная информация</Title>
                    <Descriptions
                        layout="vertical"
                        bordered
                        >
                        <Descriptions.Item label="Статус заказа" span={3}>
                            <Badge status="processing" color={order.statusBool?"green":"red"} text={order.status} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Способ оплаты">
                            {order.payment}
                        </Descriptions.Item>
                        <Descriptions.Item label="Статус оплаты" span={2}>
                            <Badge status="processing" color={order.paymentStatusBool?"green":"red"} text={order.paymentStatus} />
                        </Descriptions.Item>
                        <Descriptions.Item label={(<strong>Дата и время доставки</strong>)}>
                            {order.deliveryDateTime}
                        </Descriptions.Item>
                        <Descriptions.Item label={(<strong>Адрес доставки</strong>)} span={2}>
                            {order.deliveryAddress}
                        </Descriptions.Item>
                        {
                            order.comment?(
                                <Descriptions.Item label="Дополнительная информация" span={3}>
                                    {order.comment}
                                </Descriptions.Item>
                            ):null
                        }
                    </Descriptions>
                </div>
                <TableItems items={items} name="Товары" />
                <TableItems items={containers} name="Тара" />
                <div>
                    <Title level={4}>Итого: {order.totalSum}</Title>
                </div>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fnAuthPost: (url, data, callback) => dispatch(action.fnAuthPost(url, data, callback)),
    };
}

export default connect(null, mapDispatchToProps)(OrderShow);
