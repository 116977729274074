import React, {Component} from 'react'
import {Table, Button, Popconfirm, Input, Row, Col, Card, Statistic, Typography} from 'antd'
import {MinusOutlined, PlusOutlined, DeleteOutlined} from '@ant-design/icons'
import {isMobile} from 'react-device-detect'

const _ = require('lodash')
const InputGroup = Input.Group

class TableItems extends Component {
    constructor(props) {
        super(props);

        this.buildRows = this.buildRows.bind(this)
        this.buildColumns = this.buildColumns.bind(this)
    }

    componentDidMount = () => {
        this.props.handleChangeAmountItem({items: this.props.addedItems});
    }

    buildRows() {
        const {dataSource, addedItems, getItemPrice} = this.props;

        let rows = [];

        Object.keys(addedItems).forEach((key, index) => {
            let keyItem = findById(dataSource, parseInt(key));
            if (keyItem!==-1) {
                let item = dataSource[keyItem];
                let dataPrice = getItemPrice(item);

                rows.push({
                    key: index,
                    id: item.id,
                    item: item,
                    name: item.name,
                    price: dataPrice.price * addedItems[key],
                    amount: addedItems[key]
                })
            }
        });
        return rows;
    }

    buildColumns() {
        const {deleteItem, tebleAddItem} = this.props;
        return [
            {
                title: 'Наименование',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'Количество',
                dataIndex: 'amount',
                key: 'amount',
                width: 190,
                render: (text, record) => {
                    return (
                        <InputGroup>
                            <Row>
                                <Col span={5} className="text-center pr-5">
                                    <Button
                                        icon={<MinusOutlined />}
                                        onClick={() => tebleAddItem(record.item, false)}
                                    />
                                </Col>
                                <Col span={14} className="text-center">
                                    <Input
                                        className="text-center"
                                        size="default"
                                        disabled
                                        value={text}
                                        />
                                </Col>
                                <Col span={5} className="text-center pl-5">
                                    <Button
                                        icon={<PlusOutlined />}
                                        onClick={() => tebleAddItem(record.item, true)}
                                    />
                                </Col>
                            </Row>
                        </InputGroup>
                    )
                }
            },
            {
                title: 'Сумма',
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: '',
                dataIndex: 'action',
                key: 'action',
                width: 50,
                render: (text, record) => {
                    return (
                        <Popconfirm title="Вы уверены?" onConfirm={() => deleteItem(record.id)}>
                            <Button
                                shape="circle"
                                icon={<DeleteOutlined />}
                            />
                        </Popconfirm>
                    )
                }
            },
        ];
    }


    render() {
        const props = this.props;
        const data = this.buildRows();
        if (!data || data.length === 0) return null;

        if (isMobile) {
            return (
                <div>
                    {Object.keys(data).map(function (key, inx) {
                        return (
                            <Card
                                size="small"
                                className="mb-15"
                                title={data[key].name}
                                key={data[key].key}
                                actions={[
                                    <Statistic
                                        value={(data[key].price) + ".руб"}
                                    />,
                                    <Button onClick={() => props.deleteItem(data[key].id)} type="danger" ghost>
                                        Удалить
                                    </Button>,
                                ]}
                                >
                                <InputGroup>
                                    <Row>
                                        <Col span={5} className="text-center pr-5">
                                            <Button
                                                icon={<MinusOutlined />}
                                                onClick={() => props.tebleAddItem(data[key].item, false)}
                                            />
                                        </Col>
                                        <Col span={14} className="text-center">
                                            <Input
                                                className="text-center"
                                                size="default"
                                                disabled
                                                value={data[key].amount}
                                                />
                                        </Col>
                                        <Col span={5} className="text-center pl-5">
                                            <Button
                                                icon={<PlusOutlined />}
                                                onClick={() => props.tebleAddItem(data[key].item, true)}
                                            />
                                        </Col>
                                    </Row>
                                </InputGroup>
                            </Card>
                        )
                    })}
                    <Typography.Title level={4} className="pb-10">
                        Итого: {props.totalSumItem} руб.
                    </Typography.Title>
                </div>
            )
        }
        const columns = this.buildColumns();
        return (
            <Table
                footer={() => (
                    <strong className="footer-table">{'Итого: ' + props.totalSumItem + "  руб."}</strong>
                )}
                size="small"
                bordered={true}
                pagination={false}
                columns={columns}
                dataSource={data}
                />
        )
    }
}
export default TableItems;

function findById(array, id) {
    return _.findIndex(array, function(o) { return o.id == id})
}
