import React from 'react'
import {connect} from "react-redux"
import {Typography, Card, Modal} from 'antd'
import {ExclamationCircleOutlined} from '@ant-design/icons';

import List from '@material-ui/core/List'
import Button from '@material-ui/core/Button'

import Item from './extra/item'
import DialogEdit from './extra/dialog'
import * as action from 'stores/actions'

class Addresses extends React.PureComponent {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            list: [],
            dialogShow: false,
            dialogData: {}
        }
    }

    componentDidMount() {
        const self = this;
        const {fnAuthPost} = self.props;
        if (fnAuthPost) {
            fnAuthPost('/api/personalarea/addresses', null, function (response) {
                self.showResponse(response)
            });
        }
    }

    showResponse = response => {
        try {
            const {addresses} = response;
            this.setState({list: addresses, loading: false})

            const {handleChangeAmount} = this.props;
            if (handleChangeAmount) {
                handleChangeAmount("addresses", addresses.length)
            }
        } catch (e) {

        }
    }

    dialogShow = (value, data = {}) => {
        this.setState({
            dialogShow: value,
            dialogData: data
        })
    }

    handleSave = form => {
        const self = this;
        const {fnAuthPost} = self.props;
        if (fnAuthPost) {
            fnAuthPost('/api/personalarea/store/address', form, function (response) {
                self.dialogShow(false);
                self.showResponse(response);
            })
        }
    }

    handleDelete = id => {
        const self = this;
        const {fnAuthPost} = self.props;
        if (fnAuthPost) {
            Modal.confirm({
                title: 'Вы действительно хотите удалить адрес?',
                icon: <ExclamationCircleOutlined />,
                okText: 'Да',
                cancelText: 'Нет',
                onOk() {
                    fnAuthPost('/api/personalarea/delete/address', {id}, function (response) {
                        self.showResponse(response);
                    })
                },
            });
        }
    }

    handleFavorite = id => {
        const self = this;
        const {fnAuthPost} = self.props;
        if (fnAuthPost) {
            Modal.confirm({
                title: 'Сделать адрес избранным?',
                icon: <ExclamationCircleOutlined />,
                okText: 'Да',
                cancelText: 'Нет',
                onOk() {
                    fnAuthPost('/api/personalarea/favorite/address', {id}, function (response) {
                        self.showResponse(response);
                    })
                },
            });
        }
    }

    render() {
        const self = this;
        const {loading, list, dialogShow, dialogData} = self.state;
        return (
            <div>
                <Card
                    title="Ваши адреса"
                    extra={
                        <Button size="small" variant="outlined" color="primary" onClick={() => self.dialogShow(true)}>
                            Добавить адрес
                        </Button>
                    }
                    size="small"
                    loading={loading}
                    >
                    {list.length?(
                        <List>
                            {list.map((val, idx) => (
                                <Item key={idx} value={val} self={self} length={list.length} />
                            ))}
                        </List>
                    ):(
                        <div className="box-input-list">
                            <Typography.Title level={3}>
                                Список адресов пуст
                            </Typography.Title>
                        </div>
                    )}
                </Card>
                <DialogEdit
                    open={dialogShow}
                    data={dialogData}
                    handleClose={() => self.dialogShow(false)}
                    handleSubmit={self.handleSave}
                    />
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fnAuthPost: (url, data, callback) => dispatch(action.fnAuthPost(url, data, callback)),
    };
}

export default connect(null, mapDispatchToProps)(Addresses);
