import React from 'react';
import {Form, Row, Col, DatePicker, Button, Card} from 'antd';

import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

const Filter = ({handleSubmit}) => {
    const [form] = Form.useForm();

    return (
        <Card size="small" title="Фильтр" className="mb-25">
            <Form form={form} onFinish={handleSubmit} layout="vertical">
                <Row>
                    <Col span={8} className="pr-15">
                        <Form.Item name="dateStart" label="Дата заказа с" className="wp-100">
                            <DatePicker inputReadOnly={true} className="wp-100" format="DD.MM.YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={8} className="pr-15">
                        <Form.Item name="dateEnd" label="Дата заказа по" className="wp-100">
                            <DatePicker inputReadOnly={true} className="wp-100" format="DD.MM.YYYY" />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item className="wp-100 order-filter-button" colon={false}>
                            <Button block type="primary" htmlType="submit">
                                Применить
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}
export default Filter;
