import React from 'react'
import {Form, Input, Row, Col} from 'antd';

const AdditionalInformation = () => (
    <Row>
        <Col span={24} className="pr-10 pl-10">
            <Form.Item name="comment" label="Дополнительная информация" className="wp-100" rules={[{ required: false, message: 'Поле обязательно для заполнения' }]}>
                <Input.TextArea autoSize={{minRows: 5}}  />
            </Form.Item>
        </Col>
    </Row>
)
export default AdditionalInformation;
