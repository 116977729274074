import React, {Component} from 'react';
import {connect} from "react-redux";
import {Form, Select, DatePicker, Row, Col} from 'antd';
import {isMobile} from 'react-device-detect';
import ruRU from 'antd/lib/locale-provider/ru_RU';
import * as action from 'stores/actions';

import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru');

const {Option} = Select;
const _ = require('lodash');

const disabledDate = (value, dataSource) => {
    const {deliveryRules} = dataSource;

    let currentDay = moment().format('YYYY-MM-DD');
    let dayOfWeek = value.format('d');
    if (dayOfWeek=="0") dayOfWeek = "7";

    if (value.format('YYYY-MM-DD') < currentDay) {
        return true
    } else if (_.includes(deliveryRules.weekend, dayOfWeek)) {
        return true;
    } else if (_.includes(deliveryRules.daysOff, value.format('YYYY-MM-DD'))) {
        return true;
    }
    return false;
}

const getDeliveryTime = (values, dataSource, fn) => {
    const {deliveryRules} = dataSource;
    const {timeOfDelivery} = deliveryRules;

    if (values.deliveryDate) {
        let date = values.deliveryDate.format('YYYY-MM-DD');
        if (timeOfDelivery && timeOfDelivery[date]) {
            fn.setState(timeOfDelivery[date]);
        } else {
            fn.fnAuthPost('/api/get-timeofdelivery', {date}, (response) => {
                if (response && response.options) {
                    fn.setState(response.options);
                } else fn.setState({});
            })
        }
    } else {
        fn.setState(dataSource.timeOfDelivery)
    }
}

class DeliveryDateTime extends Component {

    constructor(props) {
        super(props)

        this.state = {
            optionList: {},
        }
    }

    setOptionList = (optionList) => this.setState({optionList})

    shouldUpdate = (prevValues, curValues) => {
        const self = this,
            {dataSource, fnAuthPost} = self.props;

        let effect = prevValues.deliveryDate !== curValues.deliveryDate;
        if (effect) {
            getDeliveryTime(curValues, dataSource, {setState: self.setOptionList, fnAuthPost})
        }
        return effect;
    }

    dateChange = (value) => {
        const {form} = this.props;
        form.setFieldsValue({deliveryTime: null})
    }

    render() {
        const self = this,
            {optionList} = self.state,
            {form, dataSource} = self.props;

        return (
            <Form.Item shouldUpdate={self.shouldUpdate.bind(self)}>
                {() => {
                    const values = form.getFieldsValue()
                    const disabled = values.deliveryDate?false:true;

                    return (
                        <Row>
                            <Col span={isMobile?24:12} className="pr-10 pl-10">
                                <Form.Item label="Дата доставки" name="deliveryDate" className="wp-100" rules={[{required: true, message: 'Поле обязательно для заполнения'}]}>
                                    <DatePicker
                                        locale={ruRU}
                                        disabledDate={(value) => disabledDate(value, dataSource)}
                                        onChange={self.dateChange}
                                        inputReadOnly={true}
                                        className="wp-100"
                                        format="DD.MM.YYYY"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={isMobile?24:12} className="pr-10 pl-10">
                                <Form.Item label="Время доставки" name="deliveryTime" className="wp-100" rules={[{required: true, message: 'Поле обязательно для заполнения' }]}>
                                    <Select
                                        disabled={disabled}>
                                        {Object.keys(optionList).map(function (key, index) {
                                            let value = optionList[key];
                                            return <Option key={index} value={key}>{value}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    )
                }}
            </Form.Item>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fnAuthPost: (url, data, callback) => dispatch(action.fnAuthPost(url, data, callback)),
    };
}

export default connect(null, mapDispatchToProps)(DeliveryDateTime);
