import React from 'react';
import {Form, Button, Row, Col, Divider, Typography, Checkbox} from 'antd';
import {isMobile} from 'react-device-detect';
import {Link} from "react-router-dom"

import DeliveryDateTime from './deliveryDateTime';
import DeliveryAddress from './deliveryAddress';
import AdditionalInformation from './additionalInformation';
import PaymentMethod from './paymentMethod';
import TableItems from './tableItems';
import Containers from '../containers';

const {Title} = Typography;
const ComponentForm = ({
    addressId,
    cart,
    dataSourceContainers,
    containers,
    addedItems,
    handleSubmit,
    dataSource,
    totalSumContainers,
    totalSumItem
}) => {
    if (cart.loading) return null;

    const [form] = Form.useForm();
    let initialValues = {
        deliveryAddressId: addressId?addressId:null,
        i_agree: true,
    }
    try {
        if (cart.addressStr && dataSource.deliveryAddress) {
            dataSource.deliveryAddress.forEach((item) => {
                if (item.name === cart.addressStr) initialValues.deliveryAddressId = item.id;
            });
        }
    } catch (e) {}

    return (
        <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={initialValues}>
            <div className={isMobile?"phone-box":""}>
                <DeliveryDateTime form={form} dataSource={dataSource}  />
                <DeliveryAddress dataSource={dataSource} />
                <AdditionalInformation dataSource={dataSource} />
                <PaymentMethod
                    form={form}
                    dataSourceContainers={dataSourceContainers}
                    dataSource={dataSource}
                    addedItems={addedItems}
                    containers={containers}/>
                <Form.Item className={"wp-100 pr-10 pl-10 mb-0"}>
                    <Title level={4}>Итого: {totalSumContainers + totalSumItem} руб.</Title>
                </Form.Item>
                <Form.Item name="i_agree" valuePropName="checked" className="wp-100 mb-10 pr-10 pl-10">
                    <Checkbox>
                        Я ознакомлен с графиком <a href="https://www.aqua-vista.ru/info/dostavka/" rel="noopener noreferrer" target="_blank">доставки</a>.
                    </Checkbox>
                </Form.Item>
            </div>
            <Form.Item shouldUpdate className="wp-100 mb-0 pr-10 pl-10">
                {() => {
                    const values = form.getFieldsValue(),
                    disabled = values.i_agree?false:true;
                    return (
                        <Button
                            disabled={disabled}
                            block={isMobile?true:false}
                            size={isMobile?"large":"default"}
                            className="mutated-button"
                            type="primary"
                            htmlType="submit">
                            Оформить заявку
                        </Button>
                    )
                }}
            </Form.Item>
        </Form>
    )
}

const ComponentTableItems = (props) => {
    if (isMobile) {
        return (
            <div>
                <div className="phone-box pt-10">
                    <Divider>Добавленные товары</Divider>
                    <Row>
                        <Col span={24}>
                            <TableItems
                                dataSource={props.dataSource.nomenclature}
                                getItemPrice={props.getItemPrice}
                                addedItems={props.addedItems}
                                deleteItem={props.deleteItem}
                                tebleAddItem={props.tebleAddItem}
                                totalSumItem={props.totalSumItem}
                                handleChangeAmountItem={props.handleChangeAmountItem}/>
                        </Col>
                        <Col className="text-center" span={24}>
                            <Link to="/products">
                                <Button className="mutated-button" type="danger" size="large">
                                    Добавить товар
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
                <Containers
                    cart={props.cart}
                    dataSource={props.dataSourceContainers}
                    handleContainersItems={props.handleContainersItems}
                    addedItems={props.addedItems}
                    nomenclature={props.dataSource.nomenclature}
                    containers={props.containers}
                    totalSumContainers={props.totalSumContainers}/>
            </div>
        )
    }
    return (
        <div>
            <Row>
                <Col span={24}>
                    <TableItems
                        dataSource={props.dataSource.nomenclature}
                        getItemPrice={props.getItemPrice}
                        addedItems={props.addedItems}
                        totalSumItem={props.totalSumItem}
                        deleteItem={props.deleteItem}
                        tebleAddItem={props.tebleAddItem}
                        handleChangeAmountItem={props.handleChangeAmountItem}/>
                </Col>
                <Col className="text-center mt-25" span={24}>
                    <Link to="/products">
                        <Button className="mutated-button" type="danger" size="large">
                            Добавить товар
                        </Button>
                    </Link>
                </Col>
            </Row>
            <Containers
                dataSource={props.dataSourceContainers}
                handleContainersItems={props.handleContainersItems}
                addedItems={props.addedItems}
                nomenclature={props.dataSource.nomenclature}
                totalSumContainers={props.totalSumContainers}
                containers={props.containers}/>
        </div>
    )
}

const FormOrder = (props) => {
    if (isMobile) {
        return (
            <Row>
                <Col span={24}>
                    <ComponentTableItems {...props} />
                </Col>
                <Col span={24}>
                    <ComponentForm {...props} />
                </Col>
            </Row>
        )
    }
    return (
        <Row>
            <Col span={12} className="pr-10">
                <ComponentTableItems {...props} />
            </Col>
            <Col span={12} className="pl-10">
                <ComponentForm {...props} />
            </Col>
        </Row>
    )
}

export default FormOrder;
