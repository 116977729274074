import React, {PureComponent} from 'react'
import {notification, message, Modal} from 'antd';
import renderHTML from 'react-render-html';

const _ = require('lodash')

class CommonOrderClass extends PureComponent {
    constructor(props) {
        super(props)

        this.state = props.cart;
    }

    handleChangeAmountItem = (data) => {
        data.totalSumItem = 0;

        const self = this;
        const {nomenclature} = this.props.newOrder;

        Object.keys(data.items).forEach((key, index) => {
            let keyItem = findById(nomenclature, parseInt(key));
            if (keyItem!==-1) {
                let item = nomenclature[keyItem];
                let dataPrice = self.getItemPrice(item, data.items[key]);

                data.totalSumItem += dataPrice.price * data.items[key]
            }
        })

        this.setState({totalSumItem: data.totalSumItem, items: data.items});
        this.props.fnUpdateCart(data);
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.cart.items !== this.props.cart.items) {
            this.setState({items: this.props.cart.items});
        }
    }

    handleContainersItems = (data) => {
        let containers = {};
        let totalSumContainers = 0;

        data.forEach((val, idx) => {
            containers[val.id] = val.amount
            totalSumContainers += val.sum;
        })

        this.props.fnUpdateCart({containers, totalSumContainers: totalSumContainers});
        this.setState({containers, totalSumContainers: totalSumContainers})
    }

    handleSubmit = values => {
        const self = this;
        const {items, containers} = self.state;

        // Установка значений
        let $data = Object.assign({}, values);
        $data.deliveryDate = $data.deliveryDate.format('YYYY-MM-DD');

        if (Object.keys(items).length>0) {
            $data.items = items;
            $data.containers = containers;
            // Отправляем заказ на сохранение
            self.props.fnSaveOrder($data, function (response) {
                if (response) {
                    if (response.message_error) {
                        Modal.info({
                            title: response.title_error?response.title_error:'Важная информация!',
                            content: renderHTML(response.message_error),
                            onOk() {},
                        });
                        return;
                    } else {
                        if (response.formUrl) {
                            window.location.replace(response.formUrl);
                        } else if (response.message) {
                            Modal.info({
                                title: 'Важная информация!',
                                content: renderHTML(response.message),
                                onOk() {},
                            });
                        }
                        self.props.fnUpdateCart({
                            totalSumItem: 0,
                            totalSumContainers: 0,
                            items: {},
                            containers: {},
                        });
                        return self.props.history.push("/");
                    }
                }
            });
        } else {
            notification.warning({
                message: 'Добавьте хотя бы один товар в заказ',
            })
        }
    }

    getItemPrice = (item, amount = 0) => {
        const {items} = this.state;

        if (!amount) {
            if (items[item.id]) {
                amount = items[item.id];
            }
        }

        let price = 0;
        let key = 0;
        if (item.pricing) {
            for (var i = 0; i < item.pricing.length; i++) {
                if (item.pricing[i].maxAmount>=amount) {
                    price = item.pricing[i].price;
                    key = i;
                    break;
                }
            }
        }

        return {price, key};
    }

    addAmountItem = (item, amount) => {
        let items = Object.assign({}, this.state.items);
        items[item.id] = amount;
        this.handleChangeAmountItem({items: items});
    }

    addItem = (item, keyPrice) => {
        let items = Object.assign({}, this.state.items);
        let amount = item.numberTransportUnits;

        if (items[item.id]) {
            amount += items[item.id];
        }

        if (item.maxAmount && amount>=item.maxAmount) {
            amount = item.maxAmount
        }

        items[item.id] = amount;

        this.handleChangeAmountItem({items: items});
        let key = keyPrice+1;
        if (item.pricing && item.pricing[key]) {
            let dataPrice = item.pricing[key];

            let priceString = item.numberTransportUnits * dataPrice.price;
            let suffixPrice = item.numberTransportUnits + ' шт';

            if (dataPrice.maxAmount>1000) {
                message.success('Товар успешно добавлен');
                return;
            }

            if ((dataPrice.maxAmount - amount)>0) {
                notification.info({
                    message: 'Добавьте еще ' + (dataPrice.maxAmount - amount),
                    description: (
                        <div>
                            <div>Товара и цена будет <strong>{priceString}.руб</strong></div>
                            <div>за <strong>{suffixPrice}</strong></div>
                        </div>
                    ),
                    duration: 3
                });
            }
        } else {
            message.success('Товар успешно добавлен');
        }
    }

    deleteItem = (id) => {
        const {items} = this.state;
        let newItems = {};

        Object.keys(items).forEach((key) => {
            if(key!=id) {
                newItems[key] = items[key];
            }
        });
        this.handleChangeAmountItem({items: newItems});
    }

    tebleAddItem = (item, type) => {
        let items = Object.assign({}, this.state.items);
        let count = item.numberTransportUnits;

        if (items[item.id]) {
            let currentAmount = items[item.id];

            if (type) {
                currentAmount += count;
            } else {
                currentAmount -= count;
            }

            if (currentAmount<count) {
                currentAmount = count;
            }

            if (item.maxAmount && currentAmount>=item.maxAmount) {
                currentAmount = item.maxAmount
            }

            items[item.id] = currentAmount;

            this.handleChangeAmountItem({items:items});

            let dataPrice = this.getItemPrice(item);
            let key = dataPrice.key + 1;

            if (item.pricing && item.pricing[key]) {
                let dataPrice = item.pricing[key];

                let priceString = item.numberTransportUnits * dataPrice.price;
                let suffixPrice = item.numberTransportUnits + ' шт';

                if (dataPrice.maxAmount>1000) {
                    message.success('Товар успешно добавлен');
                    return;
                }
                if ((dataPrice.maxAmount - currentAmount)>0) {
                    notification.info({
                        message: 'Добавьте еще ' + (dataPrice.maxAmount - currentAmount),
                        description: (
                            <div>
                                <div>Товара и цена будет <strong>{priceString}.руб</strong></div>
                                <div>за <strong>{suffixPrice}</strong></div>
                            </div>
                        ),
                        duration: 3
                    });
                }
            }
        }
    }

    render() {
        return null;
    }
}

export default CommonOrderClass;

function findById(array, id) {
    return _.findIndex(array, function(o) { return o.id == id})
}
