import React from 'react'
import {connect} from "react-redux";
import {isMobile} from 'react-device-detect'
import {Spin} from 'antd';
import ScrollableAnchor, {goToAnchor, configureAnchors} from 'react-scrollable-anchor'

import CommonOrderClass from 'components/CommonOrderClass'
import Items from './items';
import * as action from 'stores/actions';

configureAnchors({offset: 0, scrollDuration: 0})

class Products extends CommonOrderClass {

    componentDidMount = () => {
        this.props.setTitle("Товары")
        this.props.setBack(this.props.history);
        this.props.fnGetDataSourceForNewOrder();
        goToAnchor('section', false);
    }

    render() {
        const self = this;
        const {newOrder} = this.props;

        if (newOrder.loading) {
            if (isMobile) {
                return (
                    <div className="text-center phone-box pt-25">
                        <Spin size="large" />
                    </div>
                )
            }
            return (
                <div className="mt-25 text-center">
                    <Spin size="large" />
                </div>
            )
        }
        return (
            <ScrollableAnchor id="section">
            <div className={isMobile?"":"mt-25"}>
                <Items
                    dataSource={newOrder.nomenclature}
                    group={newOrder.nomenclatureGroup}
                    onChangeTab={self.onChange}
                    getItemPrice={self.getItemPrice}
                    addItem={self.addItem}
                    addAmountItem={self.addAmountItem}
                    addedItems={self.state.items}
                    />
            </div>
            </ScrollableAnchor>
        )
    }
}


const mapStateToProps = state => {
    return {
        newOrder: state.newOrder,
        cart: state.cart,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        fnSaveOrder: (form, callback) => dispatch(action.fnSaveOrder(form, callback)),
        fnAuthPost: (url, data, callback) => dispatch(action.fnAuthPost(url, data, callback)),
        fnUpdateCart: data => dispatch(action.fnUpdateCart(data)),
        fnGetDataSourceForNewOrder: form => dispatch(action.fnGetDataSourceForNewOrder(form)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);
