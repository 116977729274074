export const fnAuth = (data) => (dispatch) => {
    dispatch({ type: "LOGIN", payload: data});
}
export const fnLogout = (data) => (dispatch) => {
    dispatch({ type: "LOGOUT", payload: data});
}
export const fnGetOrders = (data) => (dispatch) => {
    dispatch({ type: "GET_ORDERS", payload: data});
}
export const fnGetDataSourceForNewOrder = (data) => (dispatch) => {
    dispatch({ type: "GET_DATASOURCE_FOR_NEW_ORDER", payload: data});
}
export const fnSaveOrder = (data, callback) => (dispatch) => {
    dispatch({ type: "SAVE_ORDER", payload: {data, callback}});
}
export const fnAuthPost = (url, data, callback) => (dispatch) => {
    dispatch({ type: "AUTH_POST", payload: {url, data, callback}});
}
export const fnUpdateUserData = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_USER_DATA", payload: {data}});
}
export const fnUpdateCart = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_CART", payload: {data}});
}
export const fnUpdateAddressId = (data) => (dispatch) => {
    dispatch({ type: "UPDATE_ADDRESS_ID", payload: data});
}
