import React from 'react';
import {Form, Input, Button} from 'antd';
import {MailOutlined, LockOutlined} from '@ant-design/icons';

import CheckboxBox from './checkbox'

const Login = (props) => {
     const [form] = Form.useForm();

     const handleSubmit = (values) => {
         const {handleSubmit} = props;
         handleSubmit('login', values);
     }

     return (
         <div>
             <Form form={form} onFinish={handleSubmit}>
                 <Form.Item name="email" rules={[{ required: true, message: 'Пожалуйста, введите ваш email!'}]}>
                     <Input size="large" prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="E-mail" />
                 </Form.Item>
                 <Form.Item name="password" rules={[{ required: true, message: 'Пожалуйста, введите ваш пароль!' }]}>
                     <Input.Password size="large" prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Пароль" />
                 </Form.Item>
                 {/*
                     <CheckboxBox />
                     <Form.Item shouldUpdate className="mb-0 text-center">
                        {() => {
                            const values = form.getFieldsValue()
                            const disabled = values.i_agree?false:true
                            return (
                                <Button disabled={disabled} size="large" htmlType="submit" className="login-form-button">
                                    Войти
                                </Button>
                            )
                        }}
                    </Form.Item>
                 */}
                 <Form.Item className="mb-0 text-center">
                     <Button size="large" htmlType="submit" className="login-form-button">
                         Войти
                     </Button>
                 </Form.Item>
             </Form>
         </div>
     );
}

export default Login;
