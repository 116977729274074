import React from 'react'
import {connect} from 'react-redux'
import {isMobile} from 'react-device-detect'

import {Typography, Layout, Row, Col} from 'antd'
import Button from '@material-ui/core/Button'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'

import {fnUpdateUserData} from 'stores/actions'
import Phones from 'components/common/phones'
import Addresses from 'components/common/addresses'
const {Title} = Typography;

class InitData extends React.PureComponent {
    constructor(props) {
        super(props)

        const {phones, address} = props.user;
        this.state = {
            loading: false,
            phones: phones,
            addresses: address,
        }

        this.handleChangeAmount = this.handleChangeAmount.bind(this)
        this.handleNext = this.handleNext.bind(this)
    }

    handleChangeAmount = (type, amount) => {
        let data = {};
        data[type] = amount;
        this.setState(data)
    }

    handleNext = () => {
        const {user, fnUpdateUserData} = this.props;
        const {phones, addresses} = this.state;

        if (user && fnUpdateUserData) {
            let data = Object.assign({}, user);
            data.phones = phones;
            data.address = addresses;

            fnUpdateUserData(data);
        }
    }

    render() {
        const self = this;
        const {user} = this.props;

        const {phones, addresses} = this.state;

        if (isMobile) {
            return (
                <>
                    <Row className="phone-box">
                        <Col span={24} className="text-center">
                            <Title>Заполните ваш адрес и контактный телефон</Title>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="mb-15">
                            <Phones user={user} handleChangeAmount={self.handleChangeAmount} />
                        </Col>
                        <Col span={24} className="mb-15">
                            <Addresses user={user} handleChangeAmount={self.handleChangeAmount} />
                        </Col>
                    </Row>
                    <Row className="phone-box">
                        <Col span={24} className="text-center">
                            <Button disabled={(phones>0 && addresses>0)?false:true} onClick={self.handleNext} variant="outlined" size="large" color="primary" endIcon={<NavigateNextIcon />}>
                                Далее
                            </Button>
                        </Col>
                    </Row>
                </>
            )
        }

        return (
            <Layout>
                <Row>
                    <Col span={24} className="text-center">
                        <Title>Заполните ваш адрес и контактный телефон</Title>
                    </Col>
                </Row>
                <Row className="mt-25">
                    <Col span={12} className="pr-15">
                        <Phones user={user} handleChangeAmount={self.handleChangeAmount} />
                    </Col>
                    <Col span={12} className="pl-15">
                        <Addresses user={user} handleChangeAmount={self.handleChangeAmount} />
                    </Col>
                </Row>
                <Row className="mt-25">
                    <Col span={24} className="text-center">
                        <Button disabled={(phones>0 && addresses>0)?false:true} onClick={self.handleNext} variant="outlined" size="large" color="primary" endIcon={<NavigateNextIcon />}>
                            Далее
                        </Button>
                    </Col>
                </Row>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fnUpdateUserData: (data) => dispatch(fnUpdateUserData(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(InitData);
