import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import reducer from './reducer';
import service from './middleware';

const middleWare = applyMiddleware(thunk, service);

const store = createStore(reducer, middleWare);

store.dispatch({type: 'INIT_APP'});

export default store;
