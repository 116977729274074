import React from 'react'
import {connect} from 'react-redux'
import {Row, Col, message} from 'antd'
import {isMobile} from 'react-device-detect'

import Phones from 'components/common/phones'
import Addresses from 'components/common/addresses'
import FormUser from './form'

import * as action from 'stores/actions'

class PersonalArea extends React.PureComponent {

    state = {
        loading: true,
        dataUser: {}
    }

    componentDidMount = () => {
        this.props.setTitle("Личные данные")
        this.props.setBack(this.props.history);

        const self = this;
        const {fnAuthPost} = self.props;
        if (fnAuthPost) {
            fnAuthPost('/api/personalarea/user', null, function (response) {
                const {data} = response;
                if (data) self.setState({loading: false, dataUser: data})
            });
        }
    }

    handleSubmit = (form) => {
        const self = this;
        const {fnAuthPost} = self.props;
        if (fnAuthPost) {
            fnAuthPost('/api/personalarea/store/user', form, function (response) {
                message.success('Данные пользователя успешно обновлены', 10);
            });
        }
    }

    render() {
        const self = this;
        const {loading, dataUser} = self.state;
        const {user} = self.props;
        return (
            <>
                <Row className={isMobile?"":"mt-25"}>
                    <Col span={isMobile?24:12} className={isMobile?"mb-15":"pr-15"}>
                        <FormUser
                            handleSubmit={self.handleSubmit}
                            loading={loading}
                            data={dataUser}
                        />
                    </Col>
                    <Col span={isMobile?24:12} className={isMobile?"":"pl-15"}>
                        <Col span={24}>
                            <Phones user={user} />
                        </Col>
                        <Col span={24} className="mt-15">
                            <Addresses user={user} />
                        </Col>
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fnAuthPost: (url, data, callback) => dispatch(action.fnAuthPost(url, data, callback)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalArea);
