import React from 'react'
import {connect} from "react-redux";
import {Link} from "react-router-dom"
import {Table, Descriptions, Button, Spin} from 'antd';
import {isMobile} from 'react-device-detect';

import Filter from './filter';
import FilterPhone from './filter.phone';

import * as action from 'stores/actions';

class Orders extends React.PureComponent {

    componentDidMount = () => {
        this.props.setTitle("Ваши заказы")
        this.props.setBack(this.props.history);
        this.props.fnGetOrders();
    }

    handleSubmit = values => {
        this.props.fnGetOrders(values);
    }

    copyOrder = order => {
        try {
            this.props.fnUpdateCart({items: order.orderItems, addressStr: order.deliveryAddress});
            this.props.history.push("/order");
        } catch (e) {

        }
    }

    renderData = () => {
        const self = this;
        const copyOrder = self.copyOrder.bind(this);
        return [
            {
                title: 'Дата создания',
                dataIndex: 'date',
                key: 'date',
            },
            {
                title: 'Заказ №',
                dataIndex: 'orderNumber',
                key: 'orderNumber',
            },
            {
                title: 'Адрес доставки',
                dataIndex: 'deliveryAddress',
                key: 'deliveryAddress',
            },
            {
                title: 'Дата и время доставки',
                dataIndex: 'deliveryDateTime',
                key: 'deliveryDateTime',
            },
            {
                title: 'Статус',
                dataIndex: 'status',
                key: 'status',
                width: 150
            },
            {
                title: 'Оплата',
                dataIndex: 'payment',
                key: 'payment',
                width: 150
            },
            {
                title: 'Действие',
                dataIndex: 'action',
                key: 'action',
                render: (text, row, index) => {
                    return (
                        <>
                            <Link to={"/order/show/" + row.id}>Просмотреть</Link>
                            <Button type="link" onClick={() => copyOrder(row)}>Копировать</Button>
                        </>
                    )
                }
            },
        ];
    }

    render() {
        const self = this;
        const {orders} = self.props;
        const copyOrder = self.copyOrder.bind(this);

        if (isMobile) {
            if (orders.loading) {
                return (
                    <div className="text-center phone-box pt-25">
                        <Spin size="large" />
                    </div>
                )
            }

            return (
                <div>
                    <FilterPhone handleSubmit={self.handleSubmit} />
                    {orders.dataSource.map((order, idx) => {
                        return (
                            <Descriptions
                                key={idx}
                                layout="vertical"
                                bordered
                                size="small"
                                className="order-box"
                                >
                                <Descriptions.Item label={"Заказ №" + order.orderNumber}>
                                    Статус: {order.status}
                                </Descriptions.Item>
                                <Descriptions.Item label={(<strong>Дата и время доставки</strong>)}>
                                    {order.deliveryDateTime}
                                </Descriptions.Item>
                                <Descriptions.Item label={(<strong>Адрес доставки</strong>)}>
                                    {order.deliveryAddress}
                                </Descriptions.Item>
                                <Descriptions.Item label="Действия">
                                    <Link to={"/order/show/" + order.id}>Просмотреть</Link>
                                    <Button type="link" onClick={() => copyOrder(order)}>Копировать</Button>
                                </Descriptions.Item>
                            </Descriptions>
                        )
                    })}
                </div>
            )
        }
        return (
            <div className="mt-25">
                <Filter handleSubmit={self.handleSubmit} />
                <Table
                    size="small"
                    loading={orders.loading}
                    bordered={true}
                    columns={self.renderData()}
                    dataSource={orders.dataSource}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        orders: state.orders,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fnGetOrders: form => dispatch(action.fnGetOrders(form)),
        fnAuthPost: (url, data, callback) => dispatch(action.fnAuthPost(url, data, callback)),
        fnUpdateCart: data => dispatch(action.fnUpdateCart(data)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Orders);
