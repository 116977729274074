import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ThemeProvider} from '@material-ui/styles';
import {createMuiTheme} from '@material-ui/core/styles';
import {ConfigProvider} from 'antd';
import ruRU from 'antd/lib/locale-provider/ru_RU';

import 'styles';
import store from 'stores/store';
// import * as serviceWorker from 'serviceWorker';

import App from 'components/App'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgb(50, 146, 225)',
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <ConfigProvider locale={ruRU}>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </Provider>
        </ConfigProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
// serviceWorker.unregister();
