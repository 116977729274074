import React from 'react'
import {connect} from "react-redux"
import {PageHeader} from 'antd';

import {RouterApp, BaseComponent} from './extra'
import * as action from 'stores/actions';

class Navigation extends BaseComponent {
    render() {
        const self = this,
            {title, backAction} = self.state,
            {menu} = this.props;

        const params = {
            setTitle: self.setTitle,
            setBack: self.setBack,
        }

        return (
            <RouterApp user={self.props.user} params={params}>
                <PageHeader
                    title={title}
                    onBack={backAction}
                    extra={self.pageHeaderExtra(menu)}
                />
            </RouterApp>
        )
    }
}

const mapStateToProps = state => {
    return {
        cart: state.cart,
        menu: state.menu,
    };
};

function mapDispatchToProps(dispatch) {
    return {
        fnLogout: form => dispatch(action.fnLogout(form)),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
