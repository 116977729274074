import React from 'react';
import {Form, Input, Card} from 'antd';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import {isMobile} from 'react-device-detect'

const FormUser = ({handleSubmit, data = {}, loading = false}) => {
    const [form] = Form.useForm();

    const requiredRule = [{ required: true, message: 'Поле обязательно для заполнения!'}];
    const rows = [
        {
            name: "surname",
            label: "Фамилия *",
            rules: requiredRule,
            span: isMobile?12:4
        },
        {
            name: "name",
            label: "Имя *",
            rules: requiredRule,
            span: isMobile?12:4
        },
        {
            name: "middle_name",
            label: "Отчество",
            span: isMobile?12:4
        },
        {
            name: "email",
            label: "E-mail *",
            rules: [
                { type: 'email', message: 'Введен неверный E-mail' },
                { required: true, message: 'Поле обязательно для заполнения!' }
            ],
            span: 12
        },
    ];

    const getInitialValues = () => {
        let initialValues = {
            id: data.id?data.id:0,
        }
        rows.forEach(val => {
            if (data[val.name]) initialValues[val.name] = data[val.name]
        })
        return initialValues;
    }

    return (
        <Card
            title="Основная информация"
            size="small"
            loading={loading}
            >
            <Form form={form} onFinish={handleSubmit} layout="vertical" initialValues={getInitialValues()}>
                <Grid container spacing={1}>
                    {rows.map((val, idx) => (
                        <Grid key={idx} xs={val.span} item>
                            <Form.Item key={idx} label={val.label} name={val.name} rules={val.rules}>
                                <Input />
                            </Form.Item>
                        </Grid>
                    ))}
                </Grid>
                <div className="text-center">
                    <Button
                        type="submit"
                        variant="outlined"
                        color="primary"
                        >
                        Сохранить
                    </Button>
                </div>
            </Form>
        </Card>
    )
}
export default FormUser;
