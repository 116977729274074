import {post, post_api} from "stores/senders";
import {notification} from 'antd';

const _ = require('lodash');
const ls = require('local-storage');

const Middleware = store => next => action => {
    next(action);
    let dataSource;

    const storage = store.getState();
    const postBase = post(next);
    const postApiBase = post_api(next);

    switch (action.type) {
        case "INIT_APP":
            postBase('/api/setting', null, function (response) {
                if (response.setting && response.menu) {
                    next({
                        type: "SET_SETTING_AND_MENU",
                        payload: {
                            setting: response.setting,
                            menu: response.menu
                        }
                    });
                }
            })
            let searchParams = new URLSearchParams(window.location.search)
            if (searchParams.has('reset_token')) {
                let token = searchParams.get('reset_token');
                next({
                    type: "SET_RESET_TOKEN",
                    payload: token
                });
            } else {
                showMessageFromSession()
                new Promise((resolve, reject) => {
                    if (!_.isNull(ls('vista-token'))) {
                        try {
                            let data = JSON.parse(ls('vista-token'))
                            next({
                                type: "LOGIN_RECEIVED",
                                payload: data
                            });
                            resolve(data);
                        } catch (e) {

                        }
                    }
                }).then((data) => {
                    postApiBase(data.token, '/api/get-unpaidorder', {}, function (response) {
                        next({
                            type: "SET_CART",
                            payload: Object.assign({}, storage.cart, response)
                        });
                    });
                })
            }
        break;
        case "LOGIN":
            dataSource = action.payload;
            new Promise((resolve, reject) => {
                if (dataSource.typeForm==='login') {
                    postBase('/api/login', dataSource.values, function (response) {
                        if (response && response.data) {
                            ls.set('vista-token', JSON.stringify(response.data));
                            next({
                                type: "LOGIN_RECEIVED",
                                payload: response.data
                            });
                            resolve(response.data);
                        }
                    });
                } else if (dataSource.typeForm==='registration') {
                    postBase('/api/registered', dataSource.values, function (response) {
                        if (response && response.data) {
                            ls.set('vista-token', JSON.stringify(response.data));
                            next({
                                type: "LOGIN_RECEIVED",
                                payload: response.data
                            });
                            resolve(response.data);
                        }
                    });
                } else if (dataSource.typeForm==='rememberPassword') {
                    postBase('/api/password-to-remind', dataSource.values, function (response) {
                        if (response && response.data) {
                            notification.success({
                                message: response.data,
                            });
                            resolve(response.data);
                        }
                    });
                } else if (dataSource.typeForm==='resetPassword') {
                    postBase('/api/password-reset', dataSource.values, function (response) {
                        if (response && response.data) {
                            sessionStorage.setItem('success', response.data);
                            window.location.href = "/"
                        }
                    });
                }
            }).then((data) => {
                postApiBase(data.token, '/api/get-unpaidorder', {}, function (response) {
                    next({
                        type: "SET_CART",
                        payload: Object.assign({}, storage.cart, response)
                    });
                });
            })
        break;
        case "LOGOUT":
            postApiBase(storage.user.token, '/api/logout', {}, function () {
                ls.remove('vista-token');
                next({
                    type: "LOGIN_RECEIVED",
                    payload: ""
                });
            });
            break;
        case "GET_ORDERS":
            postApiBase(storage.user.token, '/api/orders', action.payload, function (response) {
                next({
                    type: "SET_ORDERS",
                    payload: {
                        loading: false,
                        dataSource: response
                    }
                });
            });
            break;
        case "GET_DATASOURCE_FOR_NEW_ORDER":
            postApiBase(storage.user.token, '/api/get-dataSource-for-new-order', {}, function (response) {
                if (response) {
                    response.loading = false
                    next({
                        type: "SET_NEW_ORDER",
                        payload: response
                    });
                }
            });
            break;
        case "SAVE_ORDER":
            postApiBase(storage.user.token, '/api/order/save', action.payload.data, function (response) {
                if (action.payload.callback) {
                    action.payload.callback(response)
                }
            });
            break;
        case "AUTH_POST":
            postApiBase(storage.user.token, action.payload.url, action.payload.data, function (response) {
                if (action.payload.callback) {
                    action.payload.callback(response)
                }
            });
            break;
        case "UPDATE_USER_DATA":
            ls.set('vista-token', JSON.stringify(action.payload.data));
            next({
                type: "LOGIN_RECEIVED",
                payload: action.payload.data
            });
            break;
        case "UPDATE_CART":
            let newCart = Object.assign({}, storage.cart, action.payload.data);
            next({
                type: "SET_CART",
                payload: newCart
            });
            break;
        case "UPDATE_ADDRESS_ID":
            next({
                type: "SET_ADDRESS_ID",
                payload: action.payload
            });
            let newOrder = Object.assign({}, storage.newOrder);
            postApiBase(storage.user.token,  "api/get-nomenclature-for-address", {addressId: action.payload}, (response) => {
                if (response && response.nomenclature && Array.isArray(response.nomenclature)) {
                    newOrder.nomenclature = response.nomenclature;
                    next({
                        type: "SET_NEW_ORDER",
                        payload: newOrder
                    });
                }
            });
            break;
        default:
            break;
    }
}
export default Middleware;

function showMessageFromSession() {
    if (sessionStorage.getItem('success')) {
        let message = sessionStorage.getItem('success');

        notification.success({
            message: message,
        });
    }
    sessionStorage.clear();
}
