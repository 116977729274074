import React from 'react'
import {connect} from "react-redux";
import {Form, Select, Row, Col} from 'antd';
import * as action from 'stores/actions';
const {Option} = Select;

const DeliveryAddress = ({dataSource, fnUpdateAddressId}) => {
    const address = dataSource.deliveryAddress
    return (
        <Row>
            <Col span={24} className="pr-10 pl-10">
                <Form.Item name="deliveryAddressId" label="Адрес доставки" className="wp-100" rules={[{required: true, message: 'Поле обязательно для заполнения'}]}>
                    <Select onChange={value => fnUpdateAddressId(value)}>
                        {address.map(function (value, index) {
                            return <Option key={index} value={value.id}>{value.name}</Option>
                        })}
                    </Select>
                </Form.Item>
            </Col>
        </Row>
    )
}

const mapDispatch = (dispatch) => {
    return {
        fnUpdateAddressId: (data) => dispatch(action.fnUpdateAddressId(data)),
    };
}

export default connect(null, mapDispatch)(DeliveryAddress);
