import React from 'react';
import {connect} from "react-redux";
import {Form, Checkbox, Modal} from 'antd';
import renderHTML from 'react-render-html';

const ModalTara = (content) => {
    Modal.info({
        title: 'Условия возврата тары',
        content: renderHTML(content),
        onOk() {},
    });
}

const CheckboxBox = ({setting}) => {
    let userAgreementLink = "Политику ОПД";
    if (setting.userAgreementLink) userAgreementLink = <a href={setting.userAgreementLink} rel="noopener noreferrer" target="_blank">Политику ОПД</a>;

    let rules = "Условия возврата тары";
    if (setting.rules) rules = <a onClick={() => ModalTara(setting.rules)}>Условия возврата тары</a>;

    let linkProcessPersonalData = "на обработку персональных данных"
    if (setting.linkProcessPersonalData) linkProcessPersonalData = <a href={setting.linkProcessPersonalData} rel="noopener noreferrer" target="_blank">на обработку персональных данных</a>;

    let linkToAgreement = "Соглашение"
    if (setting.linkToAgreement) linkToAgreement = <a href={setting.linkToAgreement} rel="noopener noreferrer" target="_blank">Соглашение</a>;

    return (
        <>
            <Form.Item name="i_agree" valuePropName="checked">
                <Checkbox>
                    Я согласен {linkProcessPersonalData}, ознакомлен и принимаю {userAgreementLink}, {rules}, {linkToAgreement}.
                </Checkbox>
            </Form.Item>
        </>
    )
}

const mapStateToProps = state => {
    return { setting: state.setting };
};

export default connect(mapStateToProps)(CheckboxBox);
